import svgMap from 'svgmap';
import 'svgmap/dist/svgMap.min.css';
import {default as countries} from 'svgmap/demo/es6/local/countriesDE.js';

export default class WorldMap {
  constructor( element ) {
    element.classList.add('javascript');


    let options = {};

    options = {
      targetElementID: 'svgMap',
      hideFlag: true,
      colorMax: '#d20027',
      colorMin: '#d20027',
      mouseWheelZoomEnabled: false,
      noDataText: '',
      initialZoom: 2.5,
      initialPan: { x: 250, y: 60 },
      countryNames: countries,
      data: {
        data: {
          gdp: {
            name: 'Standort(e)',
          },
        },
        applyData: 'gdp',
        values: {
          DE: { gdp: 7},
          US: { gdp: 1 },
          AT: { gdp: 1 }
          // ...
        }
      }
    };

    new svgMap(options);
  }
}
