export default class PrivacyIframe {
  constructor(element) {
    element.classList.add('javascript');
    let button = element.querySelector('.privacyiframe-button');
    if (button) {
      button.addEventListener('click', () => {
        const iframesrc = element.dataset.src;
        let iframe = document.createElement('iframe');
        iframe.setAttribute('src', iframesrc);
        iframe.setAttribute('frameBorder', '0');
        iframe.setAttribute('class', 'privacyiframe-iframe');
        let additionalAttributes = [
          {key: 'width', value:element.dataset.width},
          {key: 'height', value:element.dataset.height},
          {key: 'allowTransparency', value:element.dataset.allowtransparency},
          {key: 'allow', value:element.dataset.allow},
        ];
        additionalAttributes.map(({key, value}) => {
          if (typeof value !== 'undefined') {
            iframe.setAttribute(key, value);
          }
        });
        element.setAttribute('style', 'opacity: 0;');
        let parent = element.parentNode;
        parent.insertBefore(iframe, element);
        element.remove();
      });
    }
  }
}
