import SimpleLightbox from 'simplelightbox';

export default class ImageLightBox{
  constructor (element){
    element.classList.add('javascript');

    new SimpleLightbox('.image-lightbox1 a');
    new SimpleLightbox('.image-lightbox2 a');
    new SimpleLightbox('.image-lightbox3 a');
    new SimpleLightbox('.image-lightbox4 a');
    new SimpleLightbox('.image-lightbox5 a');
  }
}