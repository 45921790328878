/**
 * Init Class
 *
 * The init class loads all the modules and initializes them.
 * This class can be loaded from anywhere and the init function
 * can be called on DOM ready or in any other situation (e.g.
 * when event listeners need to be reattached).
 */
import Default from '../modules/default/default';
import Slider from '../modules/slider/slider';
import Header from '../modules/header/header';
import Footer from '../modules/footer/footer';
import MainMenu from '../modules/mainMenu/mainMenu';
import Accordion from '../modules/accordion/accordion';
import Map from '../modules/map/map';
import Filter from '../modules/filter/filter';
import JobSearch from '../modules/jobSearch/jobSearch';
import VisibilityToggle from '../modules/visibilityToggle/visibilityToggle';
import References from '../modules/references/references';
import Sticky from '../modules/sticky/sticky';
import UnsupportedBrowser from '../modules/unsupported-browser/unsupportedBrowser';
import CountElement from '../modules/countElement/countElement';
import AnimateElement from '../modules/animateElement/animateElement';
import WorldMap from '../modules/worldmap/worldmap';
import Benefits from '../modules/benefits/benefits';
import ImageLightBox from '../modules/imageLightBox/imageLightBox';
import ECalendar from '../modules/e-calendar/e-calendar';
import LocationMap from '../modules/locationMap/locationMap';
import InteractiveSvg from '../modules/interactiveSvg/interactiveSvg';
import PrivacyIframe from '../modules/privacyiframe/privacyiframe';

export default class Init {
  constructor() {
    this.components = {
      'html': Default,
      '.swiper-container': Slider,
      '.site-header': Header,
      '.site-footer': Footer,
      '.site-header__navigation-wrapper': MainMenu,
      '.accordion-opener': Accordion,
      '#map': Map,
      '.filter-wrapper': Filter,
      '.job-search-form': JobSearch,
      '.visibility-toggle': VisibilityToggle,
      '#references': References,
      '.sticky': Sticky,
      '.unsupported-browser': UnsupportedBrowser,
      '.to-count-up': CountElement,
      '.to-animate': AnimateElement,
      '.worldmap': WorldMap,
      '.benefits': Benefits,
      '.gallery-wrapper': ImageLightBox,
      '.e-calendar': ECalendar,
      '.location-map-container': LocationMap,
      '.interactive-svg-container': InteractiveSvg,
      '.privacyiframe-wrapper': PrivacyIframe,
    };
  }

  /**
   * Adds all classes to a given DOM node
   * @param {dom node} element The DOM node to construct the classes for
   */
  initJSComponents(element) {
    for (const selector in this.components) {
      const elements = element.querySelectorAll(selector + ':not(.javascript)');
      const numberOfElements = elements.length;
      if (!numberOfElements) {
        continue;
      }

      const componentClass = this.components[selector];
      for (let index = 0; index < numberOfElements; index++) {
        if (selector === '.gallery-wrapper' && index !== 0) {
          break;
        }
        const element = elements[index];
        new componentClass(element);
      }
    }
  }
}
